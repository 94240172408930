@import url(../../Media.css);
@import url(../../Styles.css);

section.banner-part {
    margin-top: clamp(50px, 4vw, 100px);
    padding-top: clamp(50px, 4vw, 100px);
}

.landing-full {
    color: var(--clr-text);
}

.free-actb-btn {
    margin-top: 72px;
}

section.banner-part h1 {
    font-size: clamp(35px, 5vw, 64px);
    line-height: clamp(40px, 5vw, 70px);
    font-weight: 600;
}

section.banner-part p {
    font-size: clamp(18px, 4vw, 21px) !important;
    font-weight: 400;
    max-width: 490px;
    line-height: clamp(25px, 5vw, 30px);
}

.free-actb-btn button {
    border: 1px solid rgba(255, 255, 255, 1);
    background: var(--clr-btn1-bg);
    border-radius: 40px;
    padding: 10px 33px;
    color: var(--clr-bg);
    font-weight: 600;
    text-transform: capitalize;
    font-size: 17px;
    transition: color .2s ease-in-out, box-shadow .5s ease-in-out;
}

.free-actb-btn button:hover {
    box-shadow: inset 500px 0 0 0 rgba(19, 183, 153, 1);
    border: 1px solid rgba(19, 183, 153, 1);
}

.supported-part {
    margin-top: 60px;
    flex-wrap: wrap;
}

.report-detail h2 {
    color: rgba(20, 221, 182, 1);
    font-size: clamp(27px, 4vw, 40px);
    margin-bottom: 0;
}

.report-detail p {
    color: rgba(117, 126, 145, 1);
    margin-top: 0;
    font-size: 19px !important;
}

.banner-img img,
.bitcoin-trade img,
.cta-img img,
.device-img img,
.trade-view img,
.trading-possiblity img {
    max-width: 100%;
}

section.pro-use h2 {
    font-size: clamp(28px, 4vw, 48px);
    max-width: 750px;
}

button.btn-primary,
.trade-direct a {
    background: rgba(19, 183, 153, 1);
    border: none;
    height: 48px;
    padding: 13px 43px;
    border-radius: 40px;
    font-weight: 700;
    font-size: 15px;
    position: relative;
    cursor: pointer;
    color: var(--clr-bg);
    transition: color .2s ease-in-out, box-shadow .5s ease-in-out;
}

button.btn-primary:hover,
.trade-direct a:hover {
    box-shadow: inset 500px 0 0 0 rgb(135, 202, 190);
}

button.btn-primary:before {
    position: absolute;
    content: '';
    background: rgba(20, 221, 182, 0.15);
    width: 112px;
    height: 102px;
    top: -20px;
    left: 25px;
    filter: blur(23px);
    border-radius: 50px;
}

.trade-now {
    margin-top: clamp(20px, 5vw, 85px);
    margin-bottom: 25px;
}

section.pro-use {
    margin-top: clamp(50px, 5vw, 100px);
    position: relative;
}

section.pro-use .row-flex {
    align-items: end;
}

.graph-chart p {
    font-size: 20px;
    max-width: 450px;
}

.graph-chart img {
    width: 950px;
    height: 463px;
}

.graph-chart {
    position: absolute;
    top: 0;
    right: 89px;
}
.graph-chart-chg{
    right: unset;
    left: 0;
}
.what-cant h2 {
    font-size: clamp(25px, 4vw, 48px);
}
.what-cant h3 { 
    font-size: clamp(25px, 4vw, 45px);
}
.what-cant ul{
    padding-left: 20px;
}
.what-cant li{
    margin-bottom: 5px;
}
.what-cant li span{
    font-weight: 600;
}
/* .trade-pro-txt {
    position: absolute;
    top: 85px;
} */
section.how-work {
    margin-top: 100px;
}

section.how-work h2 {
    text-align: center;
    font-size: clamp(28px, 5vw, 48px);
}

.trading-signal h3 {
    font-size: 24px;
}

.trading-signal {
    max-width: 1100px;
    margin: 30px auto;
    background: linear-gradient(180deg, rgba(102, 175, 203, 0.1) 0%, rgba(102, 175, 203, 0) 100%);
    padding: 50px 45px;
    border-radius: 20px;
}

.trading-signal p {
    font-size: 18px;
    padding-left: 25px;
}

.coin-name p,
.coin-value p {
    margin: 0;
    padding-left: 0;
}

.coin-name p:last-child {
    font-size: 12px !important;
    color: rgba(198, 198, 198, 1);
}

.bitcoin-tag.display-2 {
    justify-content: center;
}

.btc-name.display-1 {
    gap: clamp(25px, 5vw, 50px);
    background: linear-gradient(180deg, rgba(102, 175, 203, 0.1) 0%, rgba(102, 175, 203, 0) 100%);
    padding: 20px;
    border-radius: 12px;
    margin-bottom: 10px;
    width: 83%;
    flex-wrap: wrap;
}

.coin-value {
    text-align: right
}

.coin-value p:last-child {
    font-size: 11px;
}

section.cta-box h2 {
    font-size: clamp(25px, 4vw, 48px);
    font-weight: 600;
    max-width: 670px;
}

section.cta-box p {
    font-size: 19px;
    max-width: 417px;
}

section.cta-box {
    background: url(../../Images/cta-bg.png);
    background-repeat: no-repeat;
    padding: 65px 25px;
    margin-top: 100px;
}

.buy-token {
    margin-top: 60px;
}

section.cta-box p span {
    color: rgba(20, 221, 182, 1);
}

section.upgarde-plan h2 {
    text-align: var(--center);
    font-size: clamp(33px, 5vw, 48px);
    max-width: 550px;
    margin: 100px auto 0;
}

.trade-pro h5 {
    font-size: 34px;
    font-weight: 400;
    margin: 0;
}

.trade-pro h3 {
    font-size: 55px;
    margin: 10px;
    font-weight: 400;
}

.trade-pro span {
    color: rgba(117, 126, 145, 1);
}

.pro-ticket {
    margin-top: 25px;
}

.pro-ticket button {
    border: 1px solid rgba(69, 114, 238, 1);
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
    padding: 13px 40px;
    border-radius: 40px;
    font-size: 16px;
    transition: color .2s ease-in-out, box-shadow .5s ease-in-out;
}

.pro-ticket button:hover {
    box-shadow: inset 500px 0 0 0 rgba(19, 183, 153, 1);
}

.trade-pro ul {
    padding-left: 0px;
}

.trade-pro ul li {
    margin-top: 10px;
    justify-content: center;
}

/* .trade-pro ul {} */

.grid-row {
    max-width: 850px;
    margin: 50px auto;
}

section.upgarde-plan .trade-pro {
    background: linear-gradient(180deg, rgba(0, 199, 44, 0.1) 0%, rgba(80, 211, 0, 0) 100%);
    padding: 30px;
    border-radius: 8px;
}
section.upgarde-plan .trade-pro li{
    text-align: center;
}
.ultra-pro {
    background: linear-gradient(180deg, rgba(69, 114, 238, 0.1) 0%, rgba(69, 114, 238, 0) 100%) !important;
}

section.hand-device h2 {
    font-size: clamp(25px, 4vw, 45px);
}

section.hand-device p {
    color: var(--clr-font4);
}

.progress-bar span.MuiLinearProgress-root {
    margin-bottom: 13px;
    height: 14px;
    border-radius: 50px;
    background: rgba(35, 42, 51, 1);
}

.progress-bar .MuiLinearProgress-bar {
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
}

.other-app .MuiLinearProgress-bar {
    background: linear-gradient(114deg, #C0C0C0 78%, #222A50 102%) !important;
}

.app-bar p:first-child {
    color: var(--clr-font1);
}

p.trade-fast {
    color: var(--clr-font1) !important;
    font-size: 20px;
    margin-bottom: 7px;
}

.range {
    margin-top: 40px;
}

.download-store p span {
    font-size: 22px;
}

.download-store p {
    color: #fff !important;
    max-width: 150px;
    margin: 0;
}

.download-store {
    border: 1.27px solid rgba(166, 166, 166, 1);
    padding: 5px;
    border-radius: 8px;
    background: #000;
}

.download-store svg {
    font-size: 40px;
}

.app-store.display-1 {
    flex-wrap: wrap;
    margin-top: 45px;
}

.app-store a {
    text-decoration: none;
    color: #fff;
}

.app-download {
    background: var(--clr-bg2);

    /* background: linear-gradient(113.81deg, #F5F5F5 1.84%, #C0DEFF 96.08%); */

    padding: 50px 50px 70px;
    border-radius: 15px;
    max-width: 1200px;
    margin: 50px auto;
}

section.hand-device {
    position: relative;
}

.device-img {
    position: absolute;
}

section.faq {
    margin-top: clamp(0px, 4vw, 200px);
}

section.faq h2 {
    font-size: 48px;
    text-align: center;
    color: rgba(20, 221, 182, 1);
}

section.faq .MuiPaper-root {
    margin-bottom: 25px;
    background: transparent;
    color: var(--clr-font1);
    box-shadow: none !important;
    position: relative;
}

section.faq .MuiPaper-root:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, rgba(117, 126, 145, 0) 0%, #757E91 50%, rgba(117, 126, 145, 0) 100%);
}

section.faq p {
    padding-bottom: 11px;
    font-size: clamp(18px, 4vw, 20px);
}

.question-part {
    max-width: 1200px;
    margin: 50px auto;
}

.question-part svg {
    color: #fff;
    clip-path: circle(50% at 50% 50%);
    background: var(--clr-green);
    padding: 5px;
    font-size: 30px
}

section.how-we-help {
    margin-top: clamp(40px, 5vw, 200px);
    padding-top: clamp(0px, 5vw, 50px);
}

.trade-coin p {
    margin: 0 0 8px;
}

.clicks-logo-cnt {
    margin-top: clamp(25px, 5vw, 0px);
}

.trade-coin p:nth-child(2) {
    font-size: 24px;
    font-weight: 600;
}

.trade-coin.display-1 {
    align-items: flex-start;
    gap: 15px;
    padding: 26px 0;
    margin-bottom: 15px;
    justify-content: center;
}

.trade-coin p span {
    padding: 6px 13px;
    border-radius: 40px;
    font-size: 15px;
}

.trade-coin p span.green-bg {
    background: rgba(88, 189, 125, 1);
}

.trade-coin p span.red-bg {
    background: rgba(255, 104, 56, 1);
}

.trade-box {
    background: linear-gradient(180deg, rgba(102, 175, 203, 0.1) 0%, rgba(102, 175, 203, 0) 100%);
    padding: 25px 50px;
    border-radius: 14px;
}

.trade-direct a {
    text-decoration: none;
    color: #000;
}

.trade-box {
    background: linear-gradient(180deg, rgba(102, 175, 203, 0.1) 0%, rgba(102, 175, 203, 0) 100%);
    padding: 25px;
    border-radius: 14px;
}

.trade-box .trade-coin:nth-child(2) {
    background: var(--clr-bg);
    border: 1px solid rgba(20, 221, 182, 1);
    padding: 25px;
    border-radius: 15px;
    box-shadow: 0px 64px 64px -48px #0F0F0F1A;

}

section.faq .MuiPaper-root:last-child:after {
    display: none;
}

.trade-direct a {
    text-decoration: none;
}

.help-pro-cnt {
    margin: 45px 0 0 45px;
}

.help-pro-cnt h2 {
    font-size: clamp(25px, 4vw, 48px);
    max-width: 577px;
}

.trade-chart {
    text-align: center;
}

.curve-line {
    position: relative;
}

.curve-line:after {
    position: absolute;
    content: '';
    width: 95%;
    height: 192px;
    z-index: -1;
    border-bottom: dashed 2px rgba(20, 221, 182, 1);
    border-radius: 115px 0;
    transform: rotate(21deg);
    top: -39px;
    left: 250px;
    padding: 0 98px 0 0;
}

.cta-img {
    text-align: center;
}

section.discover-endless {
    margin-top: 70px;
}

section.discover-endless h2 {
    text-align: center;
    font-size: clamp(30px, 4vw, 56px);
    -webkit-background-clip: text;
    color: transparent;
    background: linear-gradient(90deg, #FFFFFF 46.28%, rgba(255, 255, 255, 0.2) 104.96%);
    background-clip: text;
    max-width: 1200px;
    margin: 50px auto;
}

.click-bybit.display-3 {
    gap: clamp(16px, 4vw, 55px);
    margin: 150px 0;
}

.start-trade.text-center {
    width: max-content;
    margin: 35px auto;
    background: linear-gradient(0deg, #14DDB6, #14DDB6),
        radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.12) 0%, rgba(0, 0, 0, 0) 100%);
    padding: 8px 22px;
    border-radius: 40px;
    color: #000;
    box-shadow: 0px 1px 13px 0px rgba(0, 180, 129, 1);
}

.start-trade button {
    color: var(--clr-bg);
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

.copy-text button {
    height: 42px !important;
    padding: 13px 32px;
}

section.how-work .trade-coin {
    margin-bottom: 0;
}

.trade-details.display-1 {
    flex-wrap: wrap;
    justify-content: center;
}

.trade-direct {
    position: relative;
}

.trade-direct:after {
    position: absolute;
    content: '';
    bottom: 74px;
    z-index: -1;
    border-radius: 50%;
    width: 310px;
    height: 60px;
    z-index: -1;
    border-top: dashed 2px rgba(20, 221, 182, 1);
    padding: 40px 0;
    left: 0;
    transform: rotate(302deg);
}

.hand-crystal {
    position: relative;
}

.hand-crystal:after {
    position: absolute;
    background-image: url(../../Images/crystal.png);
    background-repeat: no-repeat;
    content: '';
    width: 135px;
    height: 200px;
    bottom: 29px;
    background-size: cover;
    right: -50px;
}

.lan-accord.MuiPaper-root::before {
    display: none !important;
}