.dark-theme {
    --clr-bg: #091620;
    --clr-font1: #fff;
    --clr-pop: linear-gradient(113.81deg, #000000 1.84%, #051930 96.08%);

    --clr-font2: #fff;
    --clr-font3: #000;
    --clr-text: #fff;
    --clr-btn1-bg: rgba(255, 255, 255, 1);
    --clr-bg2: linear-gradient(113.81deg, #000000 1.84%, #051930 96.08%);
    --clr-font4: rgba(174, 176, 178, 1);
    --clr-bg3: #1e1f20;
    --clr-bg4: #2d2d2d;
    --clr-bg5: #10212C;
    --clr-bg6: rgba(87, 87, 87, 1);
    --clr-green: rgba(51, 55, 66, 1);
    --border: #061829;
    --notify: #2b2e30;
    --pop: #353945;
}

.light-theme {
    --clr-bg: #fff;
    --clr-font1: #000;
    --clr-font2: #000;
    --clr-font3: #fff;
    --clr-font4: #000;
    --clr-pop: linear-gradient(113.81deg, #000000 1.84%, #051930 96.08%);
    --clr-text: #000;
    --clr-btn1-bg: #000;
    --clr-bg2: linear-gradient(113.81deg, #F5F5F5 1.84%, #C0DEFF 96.08%);
    --clr-bg3: #ccc;
    --clr-bg4: #fff;
    --clr-bg5: #fff;
    --clr-bg6: #fff;
    --clr-green: rgba(20, 221, 182, 1);
    --border: #d7dee4;
    --notify: #fff;
    --pop: #fff;
}

@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');

:root {

    /* Colors */
    --clr-green: rgba(19, 183, 153, 1);

    /* / position / */

    --position-rel: relative;
    --position-abs: absolute;
    --position-fixed: fixed;

    /* / display property / */

    --flex: flex;

    /* / aligh items / */

    --align-c: center;
    --align-l: flex-start;
    --align-r: flex-end;

    --none: none;
    /* 
    / justify / */

    --justi-c: center;
    --justi-l: flex-start;
    --justi-r: flex-end;
    --justi-sb: space-between;
    --justi-sa: space-around;
    --justi-se: space-evenly;

    /* / gap / */

    --gap-10: 10px;
    --gap-15: 15px;
    --gap-20: 20px;
    --gap-25: 25px;
    --gap-30: 30px;
    --gap-40: 40px;
    --gap-50: 50px;

    /* / flex-direction / */

    --flex-column: column;
    --flex-row: row;

    /* / flex-wrap / */

    --wrap: wrap;
    --no-wrap: nowrap;

    /* / border  / */

    --border-1px: 1px;

    /* / border-radius / */
    --radius-6px: 6px;
    --radius-8px: 8px;
    --radius-10px: 10px;
    --radius-12px: 12px;
    --radius-15px: 15px;
    --radius-20px: 20px;
    --radius-25px: 25px;

    /* / Text transform / */

    --lower: lowercase;
    --upper: uppercase;
    --capital: capitalize;

    /* / padding / */

    --pad-10px: 10px;
    --pad-5px: 5px;
    --pad-15px: 15px;
    --pad-20px: 20px;
    --pad-25px: 25px;
    --pad-30px: 30px;
    --pad-50px: 50px;

    /* / margin / */

    --mar-10px: 10px;
    --mar-15px: 15px;
    --mar-20px: 20px;
    --mar-25px: 25px;
    --mar-30px: 30px;
    --mar-50px: 50px;
    --mar-60px: 60px;
    --mar-40px: 40px;

    /* / common pixels / */

    --px-10: 10px;
    --px-20: 20px;
    --px-30: 30px;
    --px-40: 40px;
    --px-50: 50px;
    --px-60: 60px;
    --px-70: 70px;
    --px-80: 80px;
    --px-90: 90px;

    --px-100: 100px;

    --px-200: 200px;

    --px-300: 300px;

    --px-400: 400px;

    --px-500: 500px;

    /* / percentage / */

    --percent-10: 10%;
    --percent-20: 20%;
    --percent-30: 30%;
    --percent-40: 40%;
    --percent-50: 50%;
    --percent-60: 60%;
    --percent-70: 70%;
    --percent-80: 80%;
    --percent-90: 90%;
    --percent-100: 100%;

    /* / text-align / */

    --center: center;
    --left: left;
    --right: right;
    /* 
    / text color / */
    /* 
    
    / Font family / */
    --font-family: "Lexend" !important;


    /* / Font Size / */
    --font-14: 14px;
    --font-12: 12px;
    --font-16: 16px;
    --font-18: 18px;
    --font-20: 20px;
    --font-25: 25px;
    --font-30: 30px;
    --font-40: 40px;
    --font-45: 45px;

    /* / Font weight / */

    --weight-400: 400;
    --weight-600: 600;
    --weight-700: 700;
    --weight-800: 800;
    --weight-900: 900;
}




/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/*Cursor*/

.cursor {
    cursor: pointer;
}

.display-1 {
    display: flex !important;
    align-items: center !important;
    justify-content: flex-start !important;
    gap: 10px !important;
}

.display-2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.display-3 {
    display: flex;
    align-items: center;
    justify-content: center;
}

.display-4 {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.mt-10 {
    margin-top: 10px;
}

.p-10 {
    padding: 10px;
}

.mt-20 {
    margin-top: 20px;
}

.reg-free {
    color: #fff;
    /* / font-family: Lexend; / */
    font-size: 12.8px;
    font-weight: 400;
    line-height: 13.5px;
    text-align: center;
    margin-top: 15px;

}