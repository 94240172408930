.tabs-prf .MuiButtonBase-root {
    color: #777E90;
    text-transform: capitalize;
    align-items: flex-start;
}

.tabs-prf .MuiButtonBase-root.Mui-selected {
    color: var(--clr-font1);
}

.tabs-prf .MuiTabs-indicator {
    /* background-color: unset; */
    display: none;
}

.dark-theme .tabcontentbg {
    background: linear-gradient(113.81deg, #000000 1.84%, #051930 96.08%);
}

.light-theme .tabcontentbg {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.tabcontentbg {

    padding: 25px;
    border-radius: 12px;
}

.prfname {
    /* font-family: Poppins; */
    font-size: 27.34px;
    font-weight: 600;
    line-height: 36.45px;
    text-align: left;
    color: var(--clr-font1);
}

.name-email {
    /* font-family: Poppins; */
    font-size: 15.95px;
    font-weight: 500;
    line-height: 27.34px;
    text-align: left;
    color: #777E90;
}

.prf-img img {
    width: 150px;
    height: 150px;
    border-radius: 8px;
}

.prf-img {
    position: relative;
}

.verify button {
    border: 2px solid #353945;
    /* font-family: DM Sans; */
    font-size: 14px;
    color: #58BD7D;
    text-transform: capitalize;
    font-weight: 700;
    background: transparent;
    line-height: 16px;
    text-align: center;
    border-radius: 15px;
    padding: 8px 13px;
    margin-top: 15px;
}

.react-flag-select button {
    color: var(--clr-font1);
    background: #23262F;
    border-radius: 15px;
    padding: 10px;
}

.react-flag-select button::after {
    margin-left: 60px;
}

.prf-name.MuiFormControl-root {
    width: 50%;
    /* color: #FCFCFD; */
    background: var(--clr-bg);
    border-radius: 50px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


.prf-name input {
    color: var(--clr-font1);
    padding: 15px;
}


.prf-name fieldset {
    border: none;
}

.prf-hr {
    border-color: #353945 !important;
}

.radio-chk .Mui-checked+.MuiSwitch-track {
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%) !important;
}

.radio-chk .MuiSwitch-track {
    background-color: #726969 !important;
}

.pushnotify {
    /* font-family: Poppins; */
    font-size: 15.95px;
    font-weight: 500;
    color: var(--clr-font1);
    line-height: 27.34px;
    text-align: left;

}

.saveset button {
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
    /* font-family: DM Sans; */
    color: var(--clr-font3);
    font-size: 15.22px;
    font-weight: 700;
    line-height: 18.22px;
    text-align: center;
    padding: 15px 25px;
    border-radius: 25px;
    text-transform: capitalize;

}

#modal-2fa {

    /* font-family: DM Sans; */
    font-size: 40px;
    color: var(--clr-font1);
    font-weight: 700;
    line-height: 48px;
    letter-spacing: -0.01em;
    text-align: left;

}

#modal-2fa span {
    color: #58BD7D !important;

}


.qr-prf {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: fit-content;
    padding: 10px;
    margin: auto;
}

.prf-name2 .MuiFormControl-root {
    border: 2.28px solid #353945;
    border-radius: 10px;
    width: 100%;
    margin: 10px 0;

}

.prf-name2 {
    width: 400px;
}

.prf-name2 input {
    padding: 10px;
    color: var(--clr-font1);
    /* font-family: Poppins; */
    font-size: 15.99px;
    font-weight: 500;
    line-height: 27.42px;
    text-align: left;

}

.prf-name2 svg {
    fill: var(--clr-font1);
}

.prf-name2 fieldset {
    border: unset;
}

.saveset.x2 button {
    padding: 13px 105px;
}

.chgpwsd-email {
    /* font-family: Poppins; */
    font-size: 13.71px;
    font-weight: 700;
    line-height: 13.71px;
    text-align: left;
    color: #B1B5C3;
    text-transform: uppercase;

}

.nrwpswd {
    /* font-family: DM Sans; */
    font-size: 45.69px;
    color: var(--clr-font1);
    font-weight: 700;
    line-height: 54.83px;
    letter-spacing: -0.01em;
    text-align: center;

}

.cloud-icon {
    display: none !important;
}

.prf-img:hover .cloud-icon {
    display: block !important;
}

.cloud-icon {
    position: absolute;
    left: 40% !important;
    bottom: 50% !important;
}