@media (max-width: 575.98px) {
    .login-left-part {
        padding: 25px !important;
    }

    .auth-page .MuiGrid2-container {
        max-width: 95% !important;
    }

    ul.list-part {
        height: auto !important;
    }

    .log-logo img {
        width: 100%;
    }

    .mob-flex-r {
        flex-direction: column-reverse;
    }

    .mob-flex {
        flex-direction: column;
        gap: 10px;
    }

    .prf-name.MuiFormControl-root {
        width: 100% !important;
    }

    .reg-free {
        font-size: 10px !important;
    }

    .chck-label {
        font-size: 13px !important;
    }

    .md-flex {
        flex-direction: column !important;
        gap: 15px !important;
    }
}


@media (max-width: 767.98px) {
    .help-pro-cnt {
        margin: 0 !important;
    }

    .btc-name.display-1 {
        width: 100% !important;
    }

    .tab-head .MuiTabs-flexContainer {
        overflow: scroll;
    }
}

@media (max-width: 991.98px) {
    .trading-signal {
        padding: 25px !important;
    }

    .btc-name.display-1 {
        width: auto !important;
    }

    .trade-coin.display-1 {
        justify-content: flex-start !important;
    }

    .text-center.copy-text {
        text-align: left !important;
    }

    .tabs-prf {
        position: static !important;
    }

}

@media (max-width: 1199.98px) {
    .pro-use-chg .row-flex{
        flex-direction: column-reverse;
    }
    .trade-pro-txt,
    .graph-chart {
        position: static !important;
    }

    .graph-chart img {
        width: auto !important;
        height: auto !important;
        max-width: 100%;
    }

    .device-img {
        position: relative !important;
    }

    .app-download {
        padding: 20px !important;
    }

    .cta-img,
    .hand-crystal:after,
    .footer-full .footer-logo:after {
        display: none !important;
    }

    /* .select-part.display-1 {
        margin-top: 25px;
    } */
}

@media(min-width:992px) and (max-width:1199.98px) {
    .btc-name.display-1 {
        width: auto !important;
    }
}

@media(max-width:1500px) {

    .curve-line:after,
    .trade-direct:after {
        display: none !important;
    }
}

@media(min-width:1440.98px) and (max-width:2550.98px) {
    .subscription-full .MuiGrid2-root.MuiGrid2-container.MuiGrid2-direction-xs-row.MuiGrid2-spacing-xs-2 {
        flex-wrap: nowrap !important;
    }
}