.subscription-full {
    margin-top: 100px;
}

.purchase-detail h1 {
    font-size: 48px;
    margin: 0 0 5px;
}

.purchase-detail p {
    color: rgba(185, 190, 193, 1);
    font-size: 18px;
}

.tab-head .MuiTabs-flexContainer {
    justify-content: center;
    background: rgba(47, 49, 51, 1);
    width: fit-content;
    margin: 25px auto;
    border-radius: 40px;
    gap: clamp(7px, 5vw, 30px);
    height: 50px;
}

.tab-head span.MuiTabs-indicator {
    display: none;
}

.tab-head .Mui-selected {
    color: #000 !important;
    background: #fff;
    border-radius: 40px;
    padding: 0 32px;
}

.tab-head button {
    font-size: 15px;
    font-weight: 700;
    min-height: auto;
    color: rgba(235, 239, 240, 1);
}

.plan-btn button {
    background: var(--clr-bg6);
    color: rgba(19, 183, 153, 1) !important;
    font-size: 16px;
    font-weight: 500;
    text-transform: capitalize;
    width: 100%;
    border-radius: 8px;
    height: 45px;
}

.plan-card h5 {
    font-size: 20px;
    margin-bottom: 0;
}

.plan-card h3 {
    font-size: 38px;
    margin: 15px 0;
}

.plan-card p {
    max-width: 180px;
    line-height: 1.6;
    color: rgba(185, 190, 193, 1);
}

ul.list-part li svg {
    background: var(--clr-bg6);
    clip-path: circle(50% at 50% 50%);
    padding: 5px;
    color: #000;
}

ul.list-part li {
    padding-left: 0;
}

ul.list-part {
    height: 50vh;
}

.plan-card {
    background: var(--clr-bg2);
    padding: 25px;
    border-radius: 30px;
    margin-bottom: 30px;
    border: 10px solid var(--clr-bg2);
}

.plan-card:hover {
    backdrop-filter: blur(4px);
    border: 10px solid rgba(178, 255, 240, 0.5);
    transform: translateY(-50px);
    transition: all .3s ease;
    color: #fff;
    box-shadow: 0px 3px 14px 0px rgba(18, 255, 137, 0.12);
    background: linear-gradient(112.03deg, #076c60 55%, #031414 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
}

.plan-card:hover .plan-btn button {
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
    color: #fff !important;

}

.plan-card:hover ul.list-part li svg {
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
    color: #fff;
}

.plan-details {
    margin-top: 52px;
}

.plan-details .MuiGrid2-root.MuiGrid2-container.MuiGrid2-direction-xs-row {
    justify-content: center;
}

.tabs-prf {
    position: fixed;
    top: 85px;
}