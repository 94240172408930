.market-full {
  margin-top: 100px;
}

.market-full .trade-coin.display-1 {
  justify-content: flex-start;
}

.search-bar fieldset.MuiOutlinedInput-notchedOutline {
  border: none;
}

.search-bar.display-1 {
  border: 0.79px solid rgba(20, 221, 182, 1);
  background: var(--clr-bg2);
  padding: 6px 15px;
  border-radius: 10px;
}

.search-bar input {
  height: 10px !important;
  color: #fff;
  padding: 8px;
}

.select-part select {
  background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
  border-radius: 4px !important;
  height: 23px;
  padding: 6px 22px;
  padding-right: 21px !important;
}

.select-part select option {
  padding-bottom: 15px;
}

select.MuiNativeSelect-select :not([multiple]) optgroup {
  background: linear-gradient(113.81deg, #091620 1.84%, #051930 96.08%) !important;
  border: 1.07px solid rgba(20, 221, 182, 1) !important;
}

.css-1n0ddvw-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input:not([multiple]) option,
.css-1n0ddvw-MuiNativeSelect-select-MuiInputBase-input-MuiOutlinedInput-input:not([multiple]) optgroup {
  background: rgba(9, 22, 32, 1) !important;
  border: 1.07px solid rgba(20, 221, 182, 1) !important;
  color: #fff !important;
  border-radius: 10px !important;
}

.market-count.display-2,
.select-part.display-1,
.search-list.display-2 {
  flex-wrap: wrap;
  gap: 15px;
}

.view-all.text-center {
  margin-top: 50px;
}

.view-all a {
  text-decoration: none;
  color: rgba(20, 221, 182, 1);
  display: flex;
  justify-content: center;
  gap: 8px;
}

/*  */


.custom_table {
  background: var(--clr-bg5);
  border-radius: 15px;
  margin-top: 50px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.custom_table th {
  font-weight: 500;
  font-size: 15px;
  text-transform: uppercase;
  color: var(--clr-font1);
}

.custom_table th {
  border-bottom: 0 !important;

  white-space: nowrap;
}

.custom_table td {
  border-bottom: 2px solid var(--border) !important;

  /* d7dee4 */
}

.custom_table tr:last-child td {
  border-bottom: 0 !important;
}


.custom_table .flx {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.custom_table .green {
  color: #03a692;
}

.custom_table .grey {
  font-weight: 400;
  font-size: 14px;
  color: #8594a1;
}

.custom_table p {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  color: var(--clr-font1);
}

.custom_table .token_flx {
  display: flex;
  gap: 20px;
  align-items: center;
}

.custom_table .red {
  color: #e62c50;
}

.select-part .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary:focus-visible {
  outline: 0 !important;
}

.model-market :focus-visible {
  outline: none !important;
}

.tr-now button {
  background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
  color: var(--clr-bg);
  /* font-family: Poppins; */
  font-size: 15px;
  font-weight: 600;
  line-height: 36.21px;
  text-align: left;
  border-radius: 20px;
  padding: 5px 25px;
  text-transform: capitalize;
}

/* .coin {
  width: 60px;
  height: 60px;
} */

.c-name {
  color: #02BFC6;
  /* font-family: Poppins; */
  font-size: 16.9px;
  font-weight: 700;
  line-height: 28.17px;
  text-align: left;
}

.c-inner {
  /* font-family: Poppins; */
  font-size: 33.8px;
  font-weight: 600;
  line-height: 45.07px;
  text-align: left;
  color: var(--clr-font1);
  text-transform: capitalize;

}

.c-price {
  /* font-family: Lexend; */
  font-size: 24px;
  font-weight: 400;
  line-height: 30px;
  text-align: left;
  color: var(--clr-font1);
}

.c-price-inner {
  color: var(--clr-font1);
  font-size: 33.8px;
  font-weight: 600;
  line-height: 45.07px;
  text-align: right;

}

.in-detail {
  border: 1px solid #14DDB6;
  background: var(--clr-bg2);
  border-radius: 12px;
  padding: 10px 30px;
}

.in-head {
  /* //styleName: Caption 2 (Bold); */
  /* font-family: Poppins; */
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #B1B5C3;

}

.in-para {
  /* //styleName: Body 1 (Bold);
font-family: Poppins; */
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  color: var(--clr-font1);

}

.frm-marketpop.MuiFormControl-root {
  border: 1px solid #14DDB6;
  border-radius: 25px;
}

.frm-marketpop fieldset {
  border: none;
}

.frm-marketpop input {
  padding: 10px;
  border: none;
  color: var(--clr-font1);
  font-size: 21.34px;
  font-weight: 400;
  line-height: 26.67px;
  text-align: left;

}

.adormnt p {
  color: var(--clr-font1);
  /* font-family: Lexend; */
  font-size: 21.34px;
  font-weight: 400;
  line-height: 26.67px;
  text-align: center;
}

.pop-chart {
  background: linear-gradient(180deg, rgba(102, 175, 203, 0.1) 0%, rgba(102, 175, 203, 0) 100%);
  padding: 25px;
  border-radius: 12px;
}

.alertme button {
  border: 0.79px solid #14DDB6;
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #14DDB6;
  text-transform: capitalize;
  border-radius: 12px;
  padding: 15px 40px;
  background: linear-gradient(113.81deg, #091620 1.84%, #051930 96.08%);
}

.market-filterpopup {
  /* background: var(--clr-bg); */
  background: var(--clr-bg2);
  padding: 25px;

}

.top-drawer .MuiBackdrop-root {
  opacity: 0 !important;
}

.top-drawer .MuiPaper-root {
  top: 70px !important;
}

.ft1 {
  background: linear-gradient(113.81deg, #091620 1.84%, #051930 96.08%);
  border-radius: 25px;
  padding: 5px 10px;
  width: 450px;
  margin: 8px;
}

.select-mark .MuiSelect-select {
  color: var(--clr-font1);
  padding-right: 8px !important;
  padding: 10.5px 14px;
}

.select-mark svg {
  fill: var(--clr-font1);
}

.select-mark fieldset {
  border: none;
}

/* .slider-mark .MuiSlider-rail,
.slider-mark .MuiSlider-track {
  width: 250px !important;
} */

.sliderval {
  color: #14DDB6;

}

.slider-mark .MuiSlider-thumb {
  background: linear-gradient(113.81deg, #091620 1.84%, #051930 96.08%);
  border: 1px solid #ECECEC;
  box-shadow: 0px 0px 4px 0px #14DDB6;

}

.slider-mark .MuiSlider-rail {
  background: #354b69;
}

.slider-mark .MuiSlider-track {
  background: #14DDB6;
}

/* Floating animation */
@keyframes float {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
    /* Adjust this value for the "floating" height */
  }

  100% {
    transform: translateY(0);
  }
}

/* Apply animation to images */
.float {
  animation: float 4s ease-in-out infinite;
  /* Duration and easing */
  display: inline-block;
  /* margin: 10px; */
  /* Add spacing between images */
}

.mark-fil {
  position: sticky !important;
  top: 90px;
}

.float.x2 {
  transform: rotate(180deg);
  transition: transform 0.5s ease;
  animation: none;
}

.save-set button {
  background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
  /* font-family: Poppins; */
  font-size: 16px;
  font-weight: 600;
  line-height: 36.21px;
  text-align: left;
  color: #051930;
  text-transform: capitalize;
  border-radius: 25px;
  margin: 15px;
  padding: 5px 20px;
}

.numbr {
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  white-space: nowrap;
  color: #fff;
}

/* .exchnage-logo img {
  width: 120px;
  height: 80px;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 12px;
} */

.prf-table td {
  color: var(--clr-font1);
}

.classgreens {
  color: green
}

.classreds {
  color: red;
}

.view-all .MuiPagination-ul li button {
  color: rgba(20, 221, 182, 1);
  ;
}

.view-all .MuiPagination-ul li button.Mui-selected {
  border: 1px solid;
}

.view-all .MuiPagination-ul {
  justify-content: center;
}


/* From Uiverse.io by kennyotsu */
.card {
  /* color used to softly clip top and bottom of the .words container */
  /* --bg-color: #212121; */
  background-color: var(--bg-color);
  padding: 1rem 2rem;
  border-radius: 1.25rem;
}

.card p {
  margin: 0;
}

.loader,
.loader p {
  color: rgb(124, 124, 124);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 25px;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  height: 40px;
  /* padding: 10px 10px; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  border-radius: 8px;
}

.words {
  overflow: hidden;
  position: relative;
}



.words::after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(var(--bg-color) 10%,
      transparent 30%,
      transparent 70%,
      var(--bg-color) 90%);
  z-index: 20;
}

.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #14DDB6;
  animation: spin_4991 4s infinite;
}

@keyframes spin_4991 {
  10% {
    -webkit-transform: translateY(-102%);
    transform: translateY(-102%);
  }

  25% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  35% {
    -webkit-transform: translateY(-202%);
    transform: translateY(-202%);
  }

  50% {
    -webkit-transform: translateY(-200%);
    transform: translateY(-200%);
  }

  60% {
    -webkit-transform: translateY(-302%);
    transform: translateY(-302%);
  }

  75% {
    -webkit-transform: translateY(-300%);
    transform: translateY(-300%);
  }

  85% {
    -webkit-transform: translateY(-402%);
    transform: translateY(-402%);
  }

  100% {
    -webkit-transform: translateY(-400%);
    transform: translateY(-400%);
  }
}

.text-file .MuiFormControl-root {
  border: 0.79px solid rgba(20, 221, 182, 1);
  background: var(--clr-bg2);
  /* padding: 6px 15px; */
  border-radius: 10px;
}

.text-file input,
.text-file label {
  /* padding: 10px; */
  color: var(--clr-font1);
}

.text-file fieldset {
  border: none;
}

.pull-signal {
  color: var(--clr-font1) !important;
  font-size: 15px !important;
  text-transform: capitalize;
}


/* From Uiverse.io by vikramsinghnegi */

.loader-circle-9 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  background: transparent;
  border: 1px solid #3c3c3c;
  border-radius: 50%;
  text-align: center;
  line-height: 70px;
  font-family: sans-serif;
  font-size: 12px;
  color: rgba(20, 221, 182, 1);
  text-transform: uppercase;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}

.loader-circle-9:before {
  content: "";
  position: absolute;
  top: 0%;
  left: 0px;
  width: 100%;
  height: 100%;
  border: 3px solid transparent;
  border-top: 3px solid rgba(20, 221, 182, 1);
  border-right: 3px solid rgba(20, 221, 182, 1);
  border-radius: 50%;
  animation: animateC 2s linear infinite;
}

.loader-circle-9 span {
  display: block;
  position: absolute;
  top: calc(50% - 2px);
  left: 50%;
  width: 50%;
  height: 4px;
  background: transparent;
  transform-origin: left;
  animation: animate 2s linear infinite;
}

/* .loader-circle-9 span:before {
  content: "";
  position: absolute;
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: #00eaff;
  top: -12px;
  right: 0px;
  box-shadow: 0 0 20px #00eaff;
} */

@keyframes animateC {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate {
  0% {
    transform: rotate(45deg);
  }

  100% {
    transform: rotate(405deg);
  }
}

.slick-arrow {
  display: none !important;
}

.slick-dots li button:before {
  color: #ccc !important;
}
