@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap');
@import url(./Styles.css);

body {
  margin: 0;
  font-family: var(--font-family) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--clr-bg) !important;
  color: var(--text-clr);
  line-height: unset !important;
  color: var(--clr-text) !important;
}

.contain-width {
  max-width: 95%;
  margin: 0 auto;
  padding: 0 !important;
  width: 100%;
}

.MuiTableCell-root,
.MuiInputBase-root {
  font-family: var(--font-family) !important;
}



::placeholder {
  opacity: 100% !important;
  /* font-family: Poppins; */
  font-size: 14.99px;
  font-weight: 500;
  line-height: 27.42px;
  text-align: left;
  color: #777E90;


}


/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #13B799;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}