.footer-full .contain-width {
    max-width: 72%;
}

/* .footer-full a {
    color: #fff;
} */

.footer-content p,
.footer-full a {
    color: rgba(117, 126, 145, 1);
}

.footer-full {
    margin-top: 75px;
}

.footer-full .footer-logo {
    padding: 0 0 30px;
    position: relative;
}

.footer-full .footer-logo:after {
    background: linear-gradient(90deg, rgba(117, 126, 145, 0) 0%, #757E91 50%, rgba(117, 126, 145, 0) 100%);
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 12px;
}

.footer-content {
    flex-wrap: wrap;
}

.socail-media a svg:first-child {
    fill: rgba(20, 221, 182, 1);
}