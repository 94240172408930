.logo-h img {
    width: auto;
    height: 50px;
    /* padding: 10px; */
}

.side img {
    width: 100%;
    height: 100%;

}

.side {
    padding: 15px;
}

.app-bg {
    background-color: var(--clr-bg) !important;
    padding: 10px 0px;
    backdrop-filter: blur(10px);
}

.bagde {
    background: linear-gradient(112.03deg, #14DDB6 0%, #02BFC6 100%);
    margin-left: 8px;
    height: 20px;
}

.bagde span {
    font-size: 12px;
    text-transform: capitalize;

}

.bagde2 .MuiBadge-badge {
    background-color: #14DDB6;
    /* height: 10px; */
    min-width: 10px;
    top: -3px;
    right: -5px;
}

.btn-wallet button {
    border: 2px solid #353945;
    /* font-family: DM Sans; */
    font-size: 14px;
    color: var(--clr-font1);
    text-transform: capitalize;
    font-weight: 700;
    line-height: 16px;
    text-align: center;
    border-radius: 15px;
    padding: 8px 13px;

}

.drawer-m .MuiPaper-root {
    background-color: var(--clr-bg);
}

.lists .MuiButtonBase-root {
    color: var(--clr-font1);
}

.hd-select .MuiSelect-select {
    color: var(--clr-font1);
}


.hd-select fieldset {
    border: none;
}

.hd-select svg {
    fill: var(--clr-font2);
}

.hd-select {
    width: 90%;
}

.btn-wallet.x2:hover button {
    background: rgba(20, 221, 182, 1);
}

.nav-stack button {
    font-weight: 900;
}

.notify-main-cont {
    font-size: 15px;
    color: var(--clr-font1);
    border-radius: 8px;
    background-color: var(--pop);
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    margin: 10px 5px;
}

.papper .MuiPaper-root {
    background-color: var(--notify);
    width: 320px;
    height: 200px;
    padding: 15px 5px;
    overflow-y: scroll;
}

.go-svg {
    margin-left: 10px;
    /* margin-top: 3px; */
    display: none;
}

.notify-main-cont:hover .go-svg {
    display: block !important;
    /* transition: all 5s smooth; */
}

.dcard-hd {
    color: var(--clr-font1);
}

.noti-num {
    background-color: #14DDB6;
    padding: 2px 6px;
    font-size: 12px;
    border-radius: 50%;
}

.noti-num.x2 {
    padding: 5px !important;
}

.sub-noti {
    font-size: 11px;
    color: #cccccc8f;
}