.artist.colabrate {
    max-width: 100% !important;
}

.colab-msg-box {
    /* / padding: 10px;/  */
    background: var(--clr-bcg);
    border-radius: 15px;
}

.colab-text-input {
    display: flex;
    gap: 15px;
    justify-content: center;
    /* / background-color: var(--clr-font) !important;/ */
    /* background: #13B799; */
    background: var(--clr-bg2);
    padding: 5px;
    padding-bottom: 25px;
    border-radius: 0px 0px 15px 15px !important;

}

.colab-send svg {
    padding: 2px 4px;
    border-radius: 12px;
    background: linear-gradient(274.4deg, #175AFF 2.38%, #FC5AFF 97.31%) !important;
    font-size: 30px;
    fill: #fff;
}


.smile svg {
    background: #FC5AFF80 !important;
    border-radius: 15px;
    padding: 4px;
    font-size: 30px;

}

.colab-text-input input {
    /* background: #ECD4FF !important; */
    padding: 10px;
    border-radius: 12px;
    border: unset;
    width: 240px;

}

.colab-text-input fieldset {
    border: none;
}

.colab-chat-area {

    height: 600px;
    overflow-y: scroll;
    padding: 20px 20px;

}

.colab-avatar-cont {
    padding: 15px 10px;
}

.chat-area-inner-text {
    display: flex;
    gap: 15px;
    margin: 15px 0px;
}

.chat-area-inner-text.user {
    display: flex;
    gap: 15px;
    flex-direction: row-reverse;
    margin: 15px 0px;
}

.collab-send-text-main {
    padding: 15px 10px;
    background-color: var(--clr-bg3);
    border-radius: 15px;
    position: relative;
    margin-top: 10px;
    margin-right: 150px;
}

.collab-send-text-main2 {
    padding: 15px 10px;
    background-color: var(--clr-bg3);
    border-radius: 15px;
    position: relative;
    margin-top: 10px;
    margin-left: 150px;
}

.collab-send-text-main:before {
    content: "";
    display: block;
    position: absolute;
    top: 0px;
    left: -5px;
    width: 17px;
    height: 19px;
    background-color: var(--clr-bg3);
    /* / transform: translateY(-50%) rotate(332deg); /
    / transform: translateY(-50%) rotate(269deg); / */
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.collab-send-text-main2.user::after {
    content: "";
    display: block;
    position: absolute;
    top: 8px;
    right: -6px;
    width: 17px;
    height: 19px;
    background-color: var(--clr-bg3);
    /* / transform: translateY(-50%) rotate(332deg); / */
    transform: translateY(-50%) rotate(269deg);
    clip-path: polygon(0 0, 100% 0, 100% 100%);
}

.collab-send-text-main .username,
.collab-send-text-main2 .username {
    /* / font-family: Eloquia Text; / */
    font-size: 13px;
    font-weight: 600;
    line-height: 16px;
    letter-spacing: 0px;
    text-align: left;
    color: var(--clr-font1);

}

.collab-send-text-main .txt1,
.collab-send-text-main2 .txt1 {
    /* / font-family: Eloquia Text; / */
    font-size: 13px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0px;
    text-align: left;
    padding: 5px;
    color: var(--clr-font1);

}

.collab-send-text-main .time,
.collab-send-text-main2 .time {
    /* / font-family: Eloquia Text; / */
    font-size: 11px;
    font-weight: 400;
    line-height: 13px;
    letter-spacing: 0px;
    text-align: right;
    padding: 5px;
    color: var(--clr-font1);
}

div#colab {
    cursor: pointer;
    display: flex;
    width: auto !important;
    /* / display: contents; / */
    justify-content: flex-start !important;
    align-items: center;
    /* / background: transparent; /
    / max-width: none !important; / */
    max-width: 80% !important;
}

.colab-msg-box,
.msglist {
    background: var(--clr-bg2);
}

.msglist {
    padding: 15px;
    border-radius: 12px;
    height: 710px;
    overflow-y: scroll;
}

.msg-list-chat-main {
    color: var(--clr-font1) !important;
    background: transparent;
    padding: 10px 15px;
    margin: 10px 0;
}

/* From Uiverse.io by LightAndy1 */
.group {
    display: flex;
    line-height: 28px;
    align-items: center;
    position: relative;
    max-width: 100%;
}

.input {
    font-family: "Montserrat", sans-serif;
    width: 100%;
    height: 45px;
    padding-left: 2.5rem;
    box-shadow: 0 0 0 1.5px #2b2c37, 0 0 25px -17px #000;
    border: 0;
    border-radius: 12px;
    background-color: var(--clr-bg);
    outline: none;
    color: var(--clr-font1);
    transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
    cursor: text;
    z-index: 0;
}

.input::placeholder {
    color: #bdbecb;
}

.input:hover {
    box-shadow: 0 0 0 2.5px #2f303d, 0px 0px 25px -15px #000;
}

.input:active {
    transform: scale(0.95);
}

.input:focus {
    box-shadow: 0 0 0 2.5px #2f303d;
}

.search-icon {
    position: absolute;
    left: 1rem;
    fill: #bdbecb;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    z-index: 1;
}

.msg-list-chat-main.active {
    background-color: #13B799;
    border-radius: 12px;
}

.chats {
    color: var(--clr-font1);
    margin: 5px 0;
}

/* From Uiverse.io by Xtenso */
.filter-switch {
    border: 2px solid #13B799;
    border-radius: 30px;
    position: relative;
    display: flex;
    align-items: center;
    height: 40px;
    width: 180px;
    overflow: hidden;
}

.filter-switch input {
    display: none;
}

.filter-switch label {
    flex: 1;
    text-align: center;
    cursor: pointer;
    border: none;
    border-radius: 30px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: all 0.5s;
    font-weight: 500;
    font-size: 13px;
}

.filter-switch .background {
    position: absolute;
    width: 49%;
    height: 28px;
    background-color: #13B799;
    top: 4px;
    left: 4px;
    /* color: #fff !important; */
    border-radius: 30px;
    transition: left 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#option2:checked~.background {
    left: 50%;
}

#option1:checked+label[for="option1"] {
    color: #fff !important;
    font-weight: bold;
}

#option2:checked+label[for="option2"] {
    color: #fff;
    font-weight: bold;
}

#option1:not(:checked)+label[for="option1"],
#option2:not(:checked)+label[for="option2"] {
    color: var(--clr-font1);
}



/* From Uiverse.io by vinodjangid07 */
.messageBox {
    width: 90%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--clr-bg4);
    padding: 0 15px;
    border-radius: 10px;
    border: 1px solid rgb(63, 63, 63);
}

.messageBox:focus-within {
    border: 1px solid rgb(110, 110, 110);
}

.fileUploadWrapper {
    width: fit-content;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Arial, Helvetica, sans-serif;
}

#file {
    display: none;
}

.fileUploadWrapper label {
    cursor: pointer;
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.fileUploadWrapper label svg {
    height: 18px;
}

.fileUploadWrapper label svg path {
    transition: all 0.3s;
}

.fileUploadWrapper label svg circle {
    transition: all 0.3s;
}

.fileUploadWrapper label:hover svg path {
    stroke: #fff;
}

.fileUploadWrapper label:hover svg circle {
    stroke: #fff;
    fill: #3c3c3c;
}

.fileUploadWrapper label:hover .tooltip {
    display: block;
    opacity: 1;
}

.tooltip {
    position: absolute;
    top: -40px;
    display: none;
    opacity: 0;
    color: white;
    font-size: 10px;
    text-wrap: nowrap;
    background-color: #000;
    padding: 6px 10px;
    border: 1px solid #3c3c3c;
    border-radius: 5px;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.596);
    transition: all 0.3s;
}

#messageInput {
    width: 100%;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    padding-left: 10px;
    color: var(--clr-font1);
}

#messageInput:focus~#sendButton svg path,
#messageInput:valid~#sendButton svg path {
    fill: #3c3c3c;
    stroke: white;
}

#sendButton {
    width: fit-content;
    height: 100%;
    background-color: transparent;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;
}

#sendButton svg {
    height: 18px;
    transition: all 0.3s;
}

#sendButton svg path {
    transition: all 0.3s;
}

#sendButton:hover svg path {
    fill: #3c3c3c;
    stroke: white;
}

.msg-list-area {
    margin-top: 25px;
}

::placeholder {
    color: var(--clr-font1) !important;
}

.prf-name.tc.MuiFormControl-root {
    border-radius: 10px;
    width: 80%;
}

.reason-txt {
    text-align: center;
    font-weight: bolder;
    font-size: larger;
    color: red;
}

.prf-name textarea {
    color: var(--clr-font1);
}

.MuiBadge-root {
    right: 5px;
}