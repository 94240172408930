.marktab {
    background: var(--clr-bg5);
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.marktab th,
.marktab td {
    color: var(--clr-font1);
    border-bottom: 1px solid #051930;




}

.marktab th {
    /* font-family: Lexend; */
    font-size: 18.49px;
    font-weight: 600;
    line-height: 18.12px;
    font-size: 16px;
    text-align: center;
    text-transform: uppercase;
}

.marktab td {
    font-size: 18.49px;
    font-weight: 600;
    line-height: 18.12px;
    font-size: 16px;
    text-align: center;
}

.xm {
    /* font-family: Lexend; */
    font-size: 21.34px;
    color: var(--clr-font1);
    font-weight: 400;
    line-height: 26.67px;
    text-align: center;

}

.min-xm {
    /* font-family: Lexend; */
    font-size: 14.23px;
    font-weight: 400;
    line-height: 17.78px;
    text-align: center;
    color: #8594A1;

}